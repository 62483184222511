import React from "react";

type Props = {
  className?: string;
};

const IconCart = (props: Props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 492 492"
    >
      <path d="M300.19 246L484.14 62.04A26.77 26.77 0 00492 43.02c0-7.21-2.8-13.98-7.86-19.03L468.02 7.87A26.69 26.69 0 00448.98.02c-7.2 0-13.95 2.78-19.02 7.85L246.01 191.82 62.05 7.87A26.66 26.66 0 0043.02.02C35.82.02 29.06 2.8 24 7.87L7.87 24a26.93 26.93 0 000 38.05L191.83 246 7.87 429.95a26.75 26.75 0 00-7.85 19.03c0 7.2 2.79 13.96 7.85 19.03L24 484.13a26.68 26.68 0 0019.02 7.85c7.2 0 13.96-2.78 19.02-7.85L246 300.18l183.96 183.95a26.7 26.7 0 0019.02 7.85 26.7 26.7 0 0019.04-7.85L484.14 468a26.74 26.74 0 007.85-19.03c0-7.2-2.8-13.96-7.85-19.02L300.19 246z" />
    </svg>
  );
};

export default IconCart;
