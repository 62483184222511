import React from "react";

type Props = {
  className?: string;
};

const IconCart = (props: Props) => {
  const { className } = props;

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path d="M405.39 362.61c-35.2 0-63.84 28.64-63.84 63.84s28.64 63.84 63.84 63.84 63.84-28.64 63.84-63.84-28.64-63.84-63.84-63.84zm0 89.38c-14.09 0-25.54-11.46-25.54-25.54s11.45-25.53 25.54-25.53c14.08 0 25.53 11.45 25.53 25.53S419.47 452 405.4 452zM507.93 115.88a19.13 19.13 0 00-15.08-7.35H118.22L100.98 36.4a19.16 19.16 0 00-18.63-14.7h-63.2a19.15 19.15 0 100 38.3h48.09l62.24 260.44a19.15 19.15 0 0018.63 14.7h298.13c8.8 0 16.48-6 18.6-14.54l46.6-188.33a19.18 19.18 0 00-3.51-16.4zm-76.67 180.97H163.23l-35.86-150.02h341l-37.1 150.02zM173.65 362.61c-35.2 0-63.84 28.64-63.84 63.84s28.63 63.84 63.84 63.84 63.84-28.64 63.84-63.84-28.64-63.84-63.84-63.84zm0 89.38c-14.09 0-25.54-11.46-25.54-25.54s11.45-25.53 25.54-25.53 25.53 11.45 25.53 25.53S187.73 452 173.65 452z" />
    </svg>
  );
};

export default IconCart;
